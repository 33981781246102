//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SEO from '~/mixins/SEO.js'
import JSONSCHEMA from '~/mixins/JSONSCHEMA.js'
import LazyHydrate from 'vue-lazy-hydration';

export default {
  name: "index",
  components: {
        LazyHydrate,
    },
  // components: {
  // LazyHydrate,
  // },
  mixins: [SEO,JSONSCHEMA],
  data: () => ({
    page: [],
    costStore: true,
    contactVisible: false,
    // offerVisible: false,
    // travelVisible: false,
    // treatmentVisible: false,
    // testimonialsList: [],
    // costList: [],
    testimonialsOptions: {
      // loop: true,
      slidesPerView: 'auto',
      spaceBetween: 0,
    },

    costOptions: {
      // loop: true,
      lazy: true,
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    },

    // servicesList: [],
    // hospitalsList: [],
    // doctorsList: [],
    // doctorsSpecializationsList: [],
    // tvList: [],
    // bigVideo: {},
  }),

  // Preload hero images for better performance
// head(){
//   const preloadLinks = this.page.items.map(slide=>({
//     rel: 'preload',
//     as: 'image',
//     href: `https://www.imtilakgroup.com/cdn-cgi/image/format=webp,fit=cover,width=183,height=278/${slide.image}`,
//   }))
//
//   return {
//     link: preloadLinks,
//   }
// },
  methods: {
    costStorNavClick(event) {
      if (!event.target.classList.contains('active')) {
        this.costStore = !this.costStore
      }
    },
    formVisibility(isVisible) {
      this.contactVisible = isVisible;
      // console.log("Contact", this.contactVisible);
    },
    // offerVisibility(isVisible) {
    //   this.offerVisible = isVisible;
    //   console.log("offer", this.offerVisible);
    // },
    // travelVisibility(isVisible) {
    //   this.travelVisible = isVisible;
    //   console.log("travel", this.travelVisible);
    // },
    // treatmentVisibility(isVisible) {
    //   this.treatmentVisible = isVisible;
    //   console.log("treatment", this.treatmentVisible);
    // },
  },
  // async created() {
  //   try {
  //     const hospitalsResponse = await this.$api.fetchHomeHospitals()
  //     this.hospitalsList = hospitalsResponse.content;
  //     const doctorsResponse = await this.$api.fetchHomeDoctors()
  //     this.doctorsList = doctorsResponse.content;
  //     const doctorsSpecializationsResponse = await this.$api.fetchHomeDoctorsSpecializations()
  //     this.doctorsSpecializationsList = doctorsSpecializationsResponse.content;
  //     const tvResponse = await this.$api.fetchHomeTv()
  //     this.tvList = tvResponse.content;
  //     if (this.tvList.length > 0) {
  //       this.bigVideo = this.tvList[0];
  //       this.tvList.shift()
  //     }
  //   } catch (error) {
  //     this.$toast.error(error.response.data.message)
  //   }
  // },

  // async fetch() {
  //   const response = await this.$api.fetchHomePage()
  //   this.page = response.content;
  //   const testimonialsResponse = await this.$api.fetchHomeTestimonials()
  //   this.testimonialsList = testimonialsResponse.content;
  //   const costResponse = await this.$api.fetchHomeCost()
  //   this.costList = costResponse.content;
  //   const servicesResponse = await this.$api.fetchHomeServices()
  //   this.servicesList = servicesResponse.content;
  // },
async asyncData({ $api, $toast }) {
  try {
    const [
      { content: page },
      { content: testimonialsList },
      { content: costList },
      { content: servicesList },
      { content: hospitalsList },
      { content: doctorsList },
      { content: doctorsSpecializationsList },
      { content: tvList }
    ] = await Promise.all([
      $api.fetchHomePage(),
      $api.fetchHomeTestimonials(),
      $api.fetchHomeCost(),
      $api.fetchHomeServices(),
      $api.fetchHomeHospitals(),
      $api.fetchHomeDoctors(),
      $api.fetchHomeDoctorsSpecializations(),
      $api.fetchHomeTv()
    ]);

    let bigVideo = null;
    if (tvList.length > 0) {
      bigVideo = tvList[0];
      tvList.shift();
    }

    return {
      page,
      testimonialsList,
      costList,
      servicesList,
      hospitalsList,
      doctorsList,
      doctorsSpecializationsList,
      tvList,
      bigVideo
    };
  } catch (error) {
    $toast.error(error.response.data.message);
    const defaultResponse = {
      page: null,
      testimonialsList: [],
      costList: [],
      servicesList: [],
      hospitalsList: [],
      doctorsList: [],
      doctorsSpecializationsList: [],
      tvList: [],
      bigVideo: null
    };
    return defaultResponse;
  }
} ,
    }
